<template>
  <v-container>
    <v-row>
      <v-snackbar v-model="alerta.activo" multi-line vertical top right :color="alerta.color" :timeout="2000"  > 
        <strong class="subtitle-1 font-weight-black"> {{alerta.texto}} </strong>
        <template v-slot:action="{ attrs }">
          <v-btn dark text @click="alerta.activo = false" v-bind="attrs"> Cerrar </v-btn>
        </template>
      </v-snackbar>

      <v-btn fab fixed bottom left   color="primary" @click="consulta_posicion_historial()"> <v-icon >mdi-arrow-left-thick</v-icon> </v-btn>
      <v-btn color="accent" style="display: none" class="ir-arriba " dark fab fixed bottom right>
        <v-icon top>keyboard_arrow_up</v-icon>
      </v-btn>

      <v-col class="text-left">
        <v-breadcrumbs :items="seguimiento" dark large class="pl-0">
          <template v-slot:item="{ item }" >
            <v-breadcrumbs-item
              :href="item.href"
              :disabled="item.disabled"
              @click="cargarArticulos(item)"
              type="button"
            >
              <span class="text-sm-h6"> {{ item.text.toUpperCase() }}  </span> 
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
      </v-col>

      <v-col cols="12" v-if="!listado_productos.length">
        <v-alert icon="mdi-comment-alert-outline" prominent text color="accent" >
          <v-row align="center">
            <v-col class="grow text-h6">
              NO SE ENCONTRARON ARTICULOS PARA ESTA SECCIÓN.
            </v-col>
          </v-row>
        </v-alert>
      </v-col>

      <card_articulo :listado_productos="listado_productos"/>
    </v-row>
  </v-container>

</template>

<script>
  import $ from 'jquery'
  import {mapActions , mapGetters} from 'vuex'
	import metodos from '@/mixins/metodos.js';
  import Vue from 'vue'
	var accounting = require("accounting");
  Vue.filter('currency', (val, dec) => {
    return accounting.formatMoney(val, '$', dec)
  })

  import card_articulo from '@/components/card_articulo.vue'

  export default {
		mixins:[metodos],

    components: {
      card_articulo,
    },

    data (){
      return {
        idRecibo: null,
        productos:[],
        alerta: { activo: false, texto:'', color:'error' },
        url   : this.$http.options.root + 'fotos-fetish/' ,
        photo : 'nophoto.png',
      }
    },

    mounted(){
			// Jquey para activar la animacion del boton hacia arriba
			$(document).ready(function(){

				$('.ir-arriba').click(function(){
					$('body, html').animate({
						scrollTop: '0px'
					}, 300);
				});
				
				// $(window).scroll(function(){
				// 	if( $(this).scrollTop() > 0 ){
				// 		$('.ir-arriba').slideDown(300);
				// 	} else {
				// 		$('.ir-arriba').slideUp(300);
				// 	}
				// });
			});
  	},
    
    created(){
      // this.idRecibo = this.$route.params.id
      this.init();
      console.log(this.seguimiento)
    },

    computed:{
      ...mapGetters('Listado',['Loading','listado_productos','seguimiento']),
      ...mapGetters('login'  ,['getusuarioFetish']),
    },

    methods:{
      ...mapActions('Carrito',['carritoadd','CerrarCarrito']),
      ...mapActions('Listado',['buscar_productos_categoria','buscar_productos_subcategorias','guardar_historial_productos','buscar_productos_familia']),

      init(){
				$('body, html').animate({ scrollTop: '0px'}, 300);
      },

      agregar_a_carrito(item){
        const payload = new Object({
          id           : item.id,
          codigo       : item.codigo,
          nomart       : item.nomart, 
          precio1      : item.precio1,
          precio2      : item.precio2,
          pjedesc      : item.pjedesc,
          descrip      : item.descrip,
          descripLarga : item.descripLarg,
          largo        : item.largo,
          ancho        : item.ancho,
          alto         : item.alto,
          peso         : item.peso,
          foto         : item.foto,
          envoltorio   : item.envoltorio,
          cantidad     : 1
        })

        this.carritoadd(payload).then( response =>{
          this.ver_carrito()
        })
      },

      añadir_lista_deseos(item){
        const payload = new Object({
          id_cliente: this.getusuarioFetish.idcliente,
          id_producto: item.id,
        })
        this.aniadir_producto_lista_deseos(payload).then(response =>{
          this.alerta = { activo:true, texto: response, color:'blue'}
        }).catch( error =>{
          this.alerta = { activo:true, texto: error, color:'error'}
        })
      },

      cargarArticulos (item) {
        console.log('articulo',item)
        switch(item.modo){
          case 1:
            const payload = new Object({ familia:item.text, modo:1, idfamilias: item.idfamilias });
            this.buscar_productos_familia(payload)
            this.guardar_historial_productos(payload);
            if(this.$router.currentRoute.name != 'listado.productos'){  
              this.$router.push({ name: 'listado.productos' });
            }
          break;

          case 2:
            if(item.idcategorias){
              const payload2 = new Object({ familia:item.idcategorias.familia, categoria: item.idcategorias.categoria, modo:1 , idfamilias: item.idfamilias });
              this.buscar_productos_categoria(payload2);
              this.guardar_historial_productos(payload2);
              if(this.$router.currentRoute.name != 'listado.productos'){ 
                this.$router.push({ name: 'listado.productos' });
              }
            }else{
              const payload3 = new Object({ familia:item.idsubcategorias.familia, categoria: item.idsubcategorias.categoria, modo:1 , idfamilias: item.idfamilias });
              this.buscar_productos_categoria(payload3);
              this.guardar_historial_productos(payload3);
              if(this.$router.currentRoute.name != 'listado.productos'){ 
                this.$router.push({ name: 'listado.productos' });
              }
            }
          break;

          case 3:
            const payload4 = new Object({ familia:item.idsubcategorias.familia, categoria: item.idsubcategorias.categoria, subcategoria: item.idsubcategorias.subcategoria, modo:2, idfamilias: item.idfamilias });
            this.buscar_productos_subcategorias(payload4)
            this.guardar_historial_productos(payload4);
            if(this.$router.currentRoute.name != 'listado.productos'){  
              this.$router.push({ name: 'listado.productos' });
            }
          break;
        }
      },

      ver (item){
        this.$router.push({ name: 'detalle.del.producto',params:{id:item.id}})
      }

    }
  }
</script>

<style>
  .v-breadcrumbs__divider{
    color:  black !important;
  }
</style>